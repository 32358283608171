import React from 'react';
import { charts } from '../../../Assets';
import './Tokenomics.css';



function Tokenomics() {
  return (
    <div className="cfx__tokenomics section__padding" id="tokenomics">

      <div className="cfx__tokenomics-content">
        <h2>Tokenomics</h2>
        <p>What is $cbear?<br></br><br></br>
          The cbear token is a meme token deployed by the Conflux developer community,
          with a total supply of 10 billion, of which: 50% is launched fairly and 50% provides liquidity.
          {/* A 15% burn tax necessitates a 20% slippage tolerance for transactions. */}
        </p>
      </div>

      {/* <div className="cfx__tokenomics-bg">
        <img src={charts} alt="conflux meme About" />
      </div> */}

    </div>
  );
}

export default Tokenomics

