import React from 'react';
import './Buy.css';
import { camelot, ethereum, swap, wallet, dex, CfxCoin } from '../../../Assets';


const Buy = () => {
    return (
        <div className="cfx__buy section__padding" id="buy">

            <div className='cfx__buy-list'>

                <h2>How to Buy</h2>

                <div className='cfx__buy-list__item'>
                    <img src={wallet} alt="wallet" />
                    <h5>Create a wallet</h5>
                    <p>Download fluent or your wallet of choice from the app store
                        or google play store for free. Desktop users, download the google
                        chrome extension by going to confluxnetwork.org
                    </p>
                </div>

                <div className='cfx__buy-list__item'>
                    <img src={CfxCoin} alt="CfxCoin" />
                    <h5>Get some CFX</h5>
                    <p>have CFX in your wallet to switch to $cbear.
                        If you don’t have any CFX, you can buy directly on DEX,
                        transfer from another wallet, or buy on another exchange and send it to your wallet.
                    </p>
                </div>

                <div className='cfx__buy-list__item'>
                    <img src={dex} alt="swappi" />
                    <h5>Go to Swappi DEX </h5>
                    <p>connect to Swappi DEX. Go to  <a href='https://swappi.io/' target="_blank" rel="noopener noreferrer">https://swappi.io/</a> in
                        google chrome or on the browser inside your fluent app.
                        Connect your wallet. Paste the $cbear token address into Swappi,
                        select $cbear, and confirm. When fluent prompts you for a wallet signature, sign.
                    </p>
                </div>

                <div className='cfx__buy-list__item'>
                    <img src={swap} alt="swap" />
                    <h5>Switch CFX for $cbear </h5>
                    {/* <p>switch CFX for $cbear. We have 15% taxes, so set Slippage to 20% and then click buy.</p> */}
                </div>

            </div>

        </div>
    )
}

export default Buy