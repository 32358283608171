import React from 'react';
import './Roadmap.css';



const Roadmap = () => {
    return (
        <div className="cfx__roadmap section__padding" id="roadmap">

            <h2>Road Map</h2>

            <div className='cfx__roadmap-list'>

                <div className='cfx__roadmap-list__item'>
                    <h5>Phase 1</h5>
                    <ul>
                        <li>Platform Design</li>
                        <hr />
                        <li>Token Minting</li>
                        <hr />
                        <li>Website Optimization</li>
                        <hr />
                    </ul>
                </div>

                <div className='cfx__roadmap-list__item'>
                    <h5>Phase 2</h5>
                    <ul>
                        <li>Fair launch</li>
                        <hr />
                        <li>Launch Dex and provide liquidity</li>
                        <hr />
                        <li>CEX online</li>
                        <hr />
                    </ul>
                </div>

                <div className='cfx__roadmap-list__item'>
                    <h5>Phase 3</h5>
                    <ul>
                        <li>Game Development</li>
                        <hr />
                        <li>Game Testing</li>
                        <hr />
                        <li>Game  Launch</li>
                        <hr />
                    </ul>
                </div>

            </div>

        </div>
    )
}

export default Roadmap