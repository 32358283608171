import React from 'react'
import { Discord, Logo, Twitter, logo } from '../../../Assets';
import { Social } from '../../../Components';
import './Contact.css';

const Contact = () => {
  return (
    <div className="cfx__contact section__padding" id="contact">

      <h2>Contact Us</h2>

      <div className='cfx__contact-list'>

        <div className='cfx__contact-logo'>
          <img src={logo} alt="logo" />
        </div>

        <div className='cfx__home-brand'>
          <a href="" target="_blank" rel="noopener noreferrer"><Social imgUrl={Twitter} /></a>
          {/* <a href="#" target="_blank" rel="noopener noreferrer"><Social imgUrl={Discord} /></a> */}
        </div>

        <p>
          Memes are art, life, madness, and the universe.
          Memes control life and the universe.
          <br></br><br></br>
          Create together, pay tribute together, and feel the charm of memes together.

        </p>

      </div>

    </div>
  )
}

export default Contact