import React, { useRef, useState } from 'react';
import './Landing.css';
import { AiFox1, Discord, Twitter, zealy, cfx, Telegram } from '../../../Assets';
import { Social } from '../../../Components';
import CopyButton from './CopyButton'

function Landing() {
  return (
    <div className="cfx__home section__padding" id="home">

      <div className="cfx__home-content">
        <h1>Conflux Meme</h1>
        <p>The most influential Web3 meme token.<br></br>
          The days of dogs are over, now it's time to show off Cbear's power.
        </p>
        <p className='copy-button'>Address：
          <span>0x5409C2346C67BE259726Ab455Ba0B10266b2E783</span>
          <CopyButton textToCopy="0x5409C2346C67BE259726Ab455Ba0B10266b2E783" />
        </p>
        <div className='cfx__home-brand'>
          <a href="" target="_blank" rel="noopener noreferrer"><Social imgUrl={Twitter} /></a>
          <a href="" target="_blank" rel="noopener noreferrer"><Social imgUrl={Telegram} /></a>
          {/* <a href="#" target="_blank" rel="noopener noreferrer"><Social imgUrl={zealy} /></a> */}
        </div>
      </div>

      <div className="cfx__home-bg">
        <img src={cfx} alt="Aifox bg" />
      </div>


    </div>
  );
}

export default Landing