import React, { useRef, useState } from 'react';
import { copy } from '../../../Assets';
import { message } from 'antd';

const CopyButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);
  const textRef = useRef(null);

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      message.success('Copy Success', 3);
      // 可以添加一些UI状态反馈，比如显示"已复制"
    } catch (err) {
      console.error('Failed to copy: ', err);
      message.error('Failed to copy', 3);
    }
  };

  return (
    <div>
      {/* <button onClick={copyText}>{isCopied ? '已复制' : '复制文本'}</button> */}
      <img src={copy} alt="copy" onClick={copyText} />
      {/* <span>{isCopied ? 'Copy Success' : ''}</span> */}
    </div>
  );
};

export default CopyButton;