import React from 'react';
import './About.css';
import { AiFox2, about } from '../../../Assets';


function About() {
  return (
    <div className="cfx__about section__padding" id="about">

      <div className="cfx__about-bg">
        <img src={about} alt="conflux meme About" />
      </div>

      <div className="cfx__about-content">
        <h2>About Us</h2>
        <p>Cbear is the most influential MemeCoin on Conflux, the leading public chain. <br></br>
          In the near future it will become the most famous Meme token in the Web3 world. <br></br>

          Join us for a feast of memes.

        </p>
      </div>

    </div>
  );
}

export default About